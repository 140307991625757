<template>
    <section>
      <div class="p-3 bg-white mt-2 rounded-md">
        <div class="flex gap-2 items-center">
          <i class="pi pi-cog"></i>
          <p class="text-lg font-medium">Configuración</p>
        </div>
        <div class="w-full grid grid-cols-1 md:grid-cols-2 mt-4 lg:grid-cols-4 gap-4">
          <button v-if="$can('ut-segupharma.ventas.configuracion.tabla-ov')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.tabla-ov' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/edit-table.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Configuración tabla OV
            </p>
            <p class="text-gray-600">Configuración de la tabla de medicmentos al crear una orden de venta</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.bodegas-usuarios')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.bodegas-usuarios' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/bodega-usuario.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Configuración de bodegas para usuarios
            </p>
            <p class="text-gray-600">Asignación de bodegas para usuarios para la creación de orden de venta</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.config-sap')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.config-sap' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/bodega-usuario.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Configuración global OV
            </p>
            <p class="text-gray-600">Configuraciones para fechas y habilitaciones dentro de la OV</p>
          </button>
        </div>
        <div class="w-full grid grid-cols-1 md:grid-cols-2 mt-4 lg:grid-cols-4 gap-4">
          <button v-if="$can('ut-segupharma.ventas.configuracion.articulos')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.articulos' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/articulo-box.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Articulos
            </p>
            <p class="text-gray-600">Listado de articulos</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.bodegas')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.bodegas' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/bodega.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Bodegas
            </p>
            <p class="text-gray-600">Listado de bodegas</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.modalidad-contrato')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.modalidad-contrato' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/contrato.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Mod. Contrato
            </p>
            <p class="text-gray-600">Modalidades de contrato</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.motivo-autorizacion')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.motivo-autorizacion' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/mot_autoriza.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Mot. Autorización
            </p>
            <p class="text-gray-600">Motivos de autorización</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.ov-cliente')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.ov-cliente' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/orden.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Ov. Cliente
            </p>
            <p class="text-gray-600">Listado de clientes</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.pacientes')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.pacientes' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/paciente.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Pacientes
            </p>
            <p class="text-gray-600">Listado de pacientes</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.ips')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.ips' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/ips.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              IPS
            </p>
            <p class="text-gray-600">Listado de IPS's</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.localizacion')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.localizacion' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/localizacion.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Localización
            </p>
            <p class="text-gray-600">Listado de localizaciones</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.medicos')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.medicos' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/medico.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Medicos
            </p>
            <p class="text-gray-600">Listado de medicos</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.diagnosticos')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.diagnosticos' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/diagnostico.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Diagnosticos
            </p>
            <p class="text-gray-600">Listado de diagnosticos</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.frecuencias')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.frecuencias' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/frecuencia.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Frecuencias
            </p>
            <p class="text-gray-600">Listado de frecuencias</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.duracion-tratamiento')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.duracion-tratamiento' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/tratamiento.png" class="w-20" alt="">
            </div>
            <p class="font-bold text-xl">
              Dur. Tratamiento
            </p>
            <p class="text-gray-600">Duracion de tratamientos</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.seguimiento-entregas')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.seguimiento-entregas' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/seguimiento.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Seg. Entregas
            </p>
            <p class="text-gray-600">Listado de seguimiento de entregas</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.regimen')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.regimen' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/regimen.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              Regimen
            </p>
            <p class="text-gray-600">Listado de regimen</p>
          </button>
          <button v-if="$can('ut-segupharma.ventas.configuracion.calculo-cuota')" @click="$router.push({ name: 'ut-segupharma.ventas.configuracion.calculo-cuota' })" class="w-full shadow-md p-4 text-center rounded-md hover:bg-blue-100 hover:text-blue-600 transition duration-700 ease-in-out">
            <div class="w-full flex justify-center">
              <img src="../../../../../../../assets/images/calculo-cuota.png" class="w-16" alt="">
            </div>
            <p class="font-bold text-xl">
              calculo-cuota
            </p>
            <p class="text-gray-600">Listado de tasa sub anual</p>
          </button>
        </div>
      </div>
    </section>
  </template>
  <script>
    export default {
      name: 'configuracionOv',
      setup () {
        return {
        }
      }
    }
  </script>
  <style scoped>
  </style>
